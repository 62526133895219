import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Entities, fetchById, getUpdatedEntity, patch, save } from 'src/common/index';
import ErrorMessage from 'src/components/misc/error-message';
import AdminCustomerGroupEditForm from 'src/components/admin/customer-group/admin-customer-group-edit-form';
import { CustomerGroupType } from 'src/utils/constants';
import autoBind from 'react-autobind';

class AdminCustomerGroupEditModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { isCreate, id, fetchById } = this.props;

        if (!isCreate) fetchById(id, Entities.CUSTOMER_GROUP);
        else this.setState({ startedFetching: true });
    }

    componentWillReceiveProps(nextProps) {
        const { customerGroup } = nextProps;

        if (!this.state.startedFetching && customerGroup.isFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { customerGroup, isCreate, t } = this.props;

        const finishFetching = this.state.startedFetching && !customerGroup.isFetching;

        if (finishFetching) {
            // check for errors
            if (customerGroup.error) return <ErrorMessage object={customerGroup} />;
            // if everything is ok populate form
            else {
                let initialValues = {};

                if (isCreate) {
                    initialValues = {
                        type: CustomerGroupType.SCHOOL,
                    };
                }

                // populate form for editing an existing coupon
                else if (!isCreate && customerGroup.content)
                    initialValues = {
                        id: customerGroup.content.id,
                        name: t(`customer_groups.id.${customerGroup.content.id}`),
                        type: customerGroup.content.type,
                        registrationAllowed: customerGroup.content.registrationAllowed,
                        verificationNecessary: customerGroup.content.verificationNecessary,
                    };

                return (
                    <AdminCustomerGroupEditForm
                        initialValues={initialValues}
                        onSubmit={this.handleSubmit}
                        t={t}
                        ref={ref => (this.childForm = ref)}
                    />
                );
            }
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { isCreate, handleClose, patch, save } = this.props;

        if (!isCreate) patch(data, Entities.CUSTOMER_GROUP);
        else save(data, Entities.CUSTOMER_GROUP);

        handleClose();
    }
}

const mapStateToProps = state => {
    return {
        customerGroup: getUpdatedEntity(state, Entities.CUSTOMER_GROUP),
    };
};

export default connect(
    mapStateToProps,
    {
        fetchById,
        patch,
        save,
    },
    null,
    { withRef: true },
)(AdminCustomerGroupEditModal);
