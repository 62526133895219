import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import TableHelper from 'src/utils/table-helper';
import { asyncRequest, Entities, TOMCAT_URL } from 'src/common/index';
import composeModalActions from 'src/utils/modal-action-wrapper';
import ErrorMessage from 'src/components/misc/error-message';
import Toggle from 'material-ui/Toggle';
import CustomerGroupsEditModal from 'src/components/admin/customer-group/admin-customer-group-edit-modal';
import autoBind from 'react-autobind';
import { withTranslation } from 'react-i18next';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import { ButtonColors } from 'src/utils/constants';

class AdminCustomerGroupsAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        this.props.fetchAll(Entities.CUSTOMER_GROUP);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { customerGroups } = nextProps;

        if (customerGroups.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(customerGroups.items.length);
    }

    componentDidUpdate(prevProps) {
        if (
            this.tableHelper.processPaginationAfterUpdate(
                prevProps.customerGroups,
                this.props.customerGroups,
                this.table,
            )
        )
            this.setState({ renderSelection: !this.state.renderSelection });
    }

    render() {
        const { customerGroups, t } = this.props;
        const finishFetching = this.state.startedFetching && !customerGroups.isFetching;

        const tableData = [];
        if (finishFetching && customerGroups.items.length > 0) {
            customerGroups.items.map(customerGroup => {
                tableData.push({
                    id: customerGroup.id,
                    name: t(`customer_groups.id.${customerGroup.id}`),
                    type: t(`customer_groups.type.${customerGroup.type}`),
                    registrationAllowed: customerGroup.registrationAllowed,
                    verificationNecessary: customerGroup.verificationNecessary,
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('customer_group.groups')}
                    <button
                        className="btn btn-xs btn-link nav-link pull-right"
                        onClick={() => {
                            this.table.reset();
                            this.props.openModal({
                                component: CustomerGroupsEditModal,
                                componentProps: {
                                    isCreate: true,
                                },
                                title: t('customer_group.new_group'),
                                mandatoryFields: true,
                            });
                        }}>
                        <span className="glyphicon glyphicon-plus" />
                        &nbsp;{t('customer_group.new_group')}
                    </button>
                </legend>

                {(() => {
                    if (!finishFetching) return <CircularProgress />;
                    else if (customerGroups.error) return <ErrorMessage object={customerGroups} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}
                                />
                                <TableHeaderColumn columnTitle={true} dataField="name" dataSort={true}>
                                    {t('common_phrases.name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="type" dataSort={true}>
                                    {t('common_phrases.type')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="verificationNecessary"
                                    width="200"
                                    dataAlign="center"
                                    dataSort={true}
                                    dataFormat={(cell, row) =>
                                        // don't allow to enable verificationNecessary for customer group school (id 1)
                                        this.toggleFormatter(cell, row, 'verificationNecessary', row.id === 1)
                                    }>
                                    {t('customer_group.necessary_verification')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="registrationAllowed"
                                    width="80"
                                    dataAlign="center"
                                    dataSort={true}
                                    dataFormat={(cell, row) =>
                                        this.toggleFormatter(cell, row, 'registrationAllowed', false)
                                    }>
                                    {t('common_phrases.active')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    toggleFormatter(cell, busCompany, field, disabled) {
        return (
            <Toggle
                toggled={busCompany[field]}
                onToggle={() =>
                    this.props.patch(
                        {
                            [field]: !busCompany[field],
                            id: busCompany.id,
                        },
                        Entities.CUSTOMER_GROUP,
                    )
                }
                disabled={disabled}
            />
        );
    }

    onProceedDelete(customerGroup) {
        if (customerGroup) this.props.deleteById(customerGroup.id, Entities.CUSTOMER_GROUP, this.props.selectedCompany);
    }

    openConfirmDeleteDialog(customerGroup) {
        const { t } = this.props;
        this.table.reset();
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: customerGroup,
                onProceedDialog: this.onProceedDelete,
                bodyText: t('customer_group.confirm_delete'),
                proceedText: t('user_functionality.delete'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.DANGER,
            },
            title: customerGroup.name,
            noButtons: true,
        });
    }

    openCannotDeleteDialog() {
        const { t } = this.props;
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                bodyText: t('customer_group.error_has_entries'),
                proceedText: t('common_phrases.ok'),
                proceedButtonColor: ButtonColors.NOTIFY,
                isNotification: true,
            },
            title: t('common_phrases.attention'),
            noButtons: true,
        });
    }

    tableActionsFormatter(cell, customerGroup) {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    onClick={() => {
                        this.props.openModal({
                            component: CustomerGroupsEditModal,
                            componentProps: {
                                id: customerGroup.id,
                                isCreate: false,
                            },
                            title: customerGroup.name,
                            mandatoryFields: true,
                        });
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>

                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.delete')}
                    onClick={() =>
                        asyncRequest(
                            `${TOMCAT_URL}api/${Entities.CUSTOMER_GROUP.repository}/${customerGroup.id}/deletable`,
                        )
                            .then(() => this.openConfirmDeleteDialog(customerGroup))
                            .catch(() => this.openCannotDeleteDialog())
                    }>
                    <span className="glyphicon glyphicon-trash text-danger" />
                </button>
            </div>
        );
    }
}

export default composeModalActions(withTranslation()(AdminCustomerGroupsAdministration));
